import { Fragment } from "react";
import { Route } from "react-router-dom";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useSelector } from "react-redux";
import history from "./../../../common/services/history";
import { headerMobile } from "../../../common/components/Header/HeaderStyles";
import HeaderBackButton from "../../../common/components/Header/HeaderBackButton";
import { colors } from "../../../common/styles/Colors";
import Icon from "../../../common/components/UI/Icon";

const HeaderMyProgrammeMobile = () => {
    const { studentProfile } = useSelector(state => state.MyProgramme);
    const { lseIdNumber, isMasqueraded } = useSelector(state => ({
        lseIdNumber: state.Auth.user.lseIdNumber,
        isMasqueraded: state.Auth.isMasqueraded,
    }));
    const { studentsList } = useSelector(state => state.MyProgramme);

    return (
        <div css={[headerMobile.body, isMasqueraded && headerMobile.bodyIsMasqueraded]}>
            <div css={headerMobile.shape} />
            <div css={headerMobile.inner}>
                <div css={headerMobile.elementLeft}>
                    {studentsList &&
                        studentsList[0] &&
                        studentsList[0].students &&
                        studentsList[0].students.length > 0 && (
                            <HeaderBackButton
                                icon="arrowLeft"
                                iconColor={colors.white}
                                onClick={() =>
                                    history.push({
                                        pathname: "/myprogramme",
                                    })
                                }
                            />
                        )}

                    <div />
                    <div css={headerMobile.elementTitle}>
                        <div css={headerMobile.title}>
                            <Route
                                exact
                                path="/myprogramme"
                                render={() => <Fragment>My Programmed</Fragment>}
                            />
                        </div>
                    </div>
                </div>
                <div css={headerMobile.elementRight}>
                    {studentProfile &&
                        studentProfile.playRef &&
                        studentProfile.playRef === lseIdNumber && (
                            <button
                                type="button"
                                css={headerMobile.actionRight}
                                onClick={() => history.push("/profile")}
                            >
                                <Icon name="edit" width={18} height={18} fill={colors.white} />
                            </button>
                        )}
                </div>
            </div>
        </div>
    );
};

export default HeaderMyProgrammeMobile;
