import { Component } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { headerMobile } from "./HeaderStyles";
import HeaderBackButton from "./HeaderBackButton";
import { colors } from "../../styles/Colors";
import history from "../../services/history";
import Icon from "../UI/Icon";
import { connect } from "react-redux";

class HeaderSupportMapMobile extends Component {
    render() {
        const {
            title,
            hideLeftElement,
            isSearchActive,
            customBackAction,
            toggleSearch,
            isMasqueraded,
        } = this.props;

        return (
            <div css={[headerMobile.body, isMasqueraded && headerMobile.bodyIsMasqueraded]}>
                <div css={headerMobile.shape} />
                <div css={headerMobile.inner}>
                    <div css={headerMobile.elementLeft}>
                        {!hideLeftElement && !isSearchActive && (
                            <HeaderBackButton
                                iconColor={colors.white}
                                href={customBackAction ? customBackAction : history.goBack}
                            />
                        )}
                        <div css={headerMobile.elementTitle}>
                            <div css={headerMobile.title}>{title}</div>
                        </div>
                    </div>
                </div>
                <div css={headerMobile.elementRight}>
                    {!isSearchActive && (
                        <button onClick={toggleSearch} css={headerMobile.actionRight}>
                            <Icon name="search" width="18" height="18" fill={colors.white} />
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ Auth: { isMasqueraded } }) => ({
    isMasqueraded,
});

export default connect(mapStateToProps)(HeaderSupportMapMobile);
