/*global google*/
import { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Container } from "../../common/components/UI/Container";
import ButtonFloating from "../../common/components/UI/ButtonFloating";
import { campusMapping, buildingShort, buildingInfo } from "./CampusMappingStyles";
import { colors } from "../../common/styles/Colors";
import SearchMapField from "../Search/SearchMapField";
import ResultsMapList from "../Search/ResultsMapList";
import Icon from "../../common/components/UI/Icon";
import actionCreators from "../../store/CampusMapping/actionCreators";
import Parser from "html-react-parser";
import Button from "../../common/components/UI/Button";
import { markerColors } from "./helpers";
import GTM from "../../common/services/GTM";

const campusMappingMode = {
    LIST: "LIST",
    DETAIL: "DETAIL",
};

const DEFAULT_ZOOM = 16;
const DEFAULT_CENTER = { lat: 51.515326, lng: -0.11735 };

const Map = withScriptjs(
    withGoogleMap(({ children, showZoomControl, zoom, center, onMapMounted }) => (
        <GoogleMap
            defaultZoom={zoom}
            defaultCenter={center}
            zoom={zoom}
            center={center}
            options={{
                streetViewControl: false,
                scaleControl: false,
                mapTypeControl: false,
                panControl: false,
                zoomControl: showZoomControl,
                rotateControl: false,
                fullscreenControl: false,
            }}
            ref={onMapMounted}
        >
            {children}
        </GoogleMap>
    ))
);

const BuildingItem = ({ building, showBuildingDetails, index }) => (
    <button type="button" css={buildingShort.body} onClick={() => showBuildingDetails(building.id)}>
        <span css={buildingShort.elementLeft}>
            <span css={buildingShort.name}>{building.name}</span>
            <span css={buildingShort.address}>{building.address}</span>
        </span>
        <span css={buildingShort.elementRight}>
            <span css={buildingShort.code}>
                <span css={[buildingShort.codeInner, { backgroundColor: markerColors[index] }]}>
                    {!!building.code && <span css={buildingShort.codeText}>{building.code}</span>}
                </span>
                <span css={buildingShort.shapeBottomCode}>
                    <svg preserveAspectRatio="none" viewBox="0 0 32 4" width="100%" height="4">
                        <path d="M0 0h32L0 4z" fill={markerColors[index]} />
                    </svg>
                </span>
            </span>
        </span>
    </button>
);

const MarkerItem = ({ currentId, building, showBuildingDetails, index }) => {
    return (
        <Marker
            position={{
                lat: Number(building.latitude),
                lng: Number(building.longitude),
            }}
            label={{
                text: building.code ? building.code : " ",
                color: "#fff",
                fontSize: building.id === Number(currentId) ? "12px" : "8px",
                fontWeight: "bold",
            }}
            icon={{
                path: "M24.286 29.929V42l-10.73-9.389L0 36V0h32v28l-7.714 1.929z",
                fillColor: markerColors[index],
                fillOpacity: 1,
                strokeWeight: 0,
                strokeColor: "transparent",
                scale: building.id === Number(currentId) ? 1 : 0.7,
                anchor: new google.maps.Point(24, 42),
                labelOrigin: new google.maps.Point(16, 16),
            }}
            zIndex={building.id === Number(currentId) ? 999 : index}
            onClick={() => showBuildingDetails(building.id)}
        />
    );
};

class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMapFullscreen: false,
            mode: campusMappingMode.LIST,
            building: null,
            isSearchResult: false,
            showZoomControl: window.innerWidth < 1024 ? false : true,
            zoom: DEFAULT_ZOOM,
            center: DEFAULT_CENTER,
            mapRef: null,
        };
    }

    componentDidMount() {
        const { getLocations } = this.props;
        getLocations();
        window.addEventListener("resize", this.updateDimensions);

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/campus-mapping`,
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props;
        if (location && location.id > 0) {
            if (
                !prevProps.location ||
                (prevProps.location && prevProps.location.id !== location.id)
            ) {
                this.state.mapRef.panTo({
                    lat: Number(location.latitude),
                    lng: Number(location.longitude),
                });

                this.setState({
                    zoom: 19,
                    // center: { lat: Number(location.latitude), lng: Number(location.longitude) },
                });
            }
        }
    }

    selectBuilding = id => {
        const { toggleSearchActive, isSearchActive, clearLocationDetails } = this.props;
        const { showBuildingDetails } = this;
        if (isSearchActive && id > 0) {
            this.setState({ isSearchResult: true });
            clearLocationDetails();
            toggleSearchActive();
            showBuildingDetails(id);
        }
    };

    changeMode = mode => {
        this.setState({
            mode: mode,
        });
    };

    toggleFullscreenMap = () => {
        this.setState({
            isMapFullscreen: !this.state.isMapFullscreen,
        });
    };

    showBuildingDetails = id => {
        const { isMapFullscreen } = this.state;
        const { changeMode, toggleFullscreenMap } = this;
        const { getLocationDetails } = this.props;
        getLocationDetails({ id: id });
        isMapFullscreen && toggleFullscreenMap();
        changeMode(campusMappingMode.DETAIL);
        this.setState({ building: id });
    };

    hideBuildingDetails = () => {
        const { clearLocationDetails } = this.props;
        const { changeMode } = this;
        clearLocationDetails();
        changeMode(campusMappingMode.LIST);
        this.setState({
            building: null,
            zoom: DEFAULT_ZOOM,
        });
        this.state.mapRef.panTo({
            lat: Number(DEFAULT_CENTER.lat),
            lng: Number(DEFAULT_CENTER.lng),
        });
    };

    updateDimensions = () => {
        this.setState({
            showZoomControl: window.innerWidth < 1024 ? false : true,
        });
    };

    onMapMounted = mapRef => {
        this.setState({ mapRef: mapRef });
    };

    render() {
        const { isMapFullscreen, mode, showZoomControl, zoom, center, building } = this.state;
        const {
            toggleFullscreenMap,
            showBuildingDetails,
            hideBuildingDetails,
            selectBuilding,
        } = this;
        const {
            locations,
            location,
            isSearchActive,
            match: {
                params: { id },
            },
            isMasqueraded,
        } = this.props;

        const locationIndex = building !== null && locations.findIndex(x => x.id === building);

        return (
            <div css={campusMapping.body}>
                <Fragment>
                    <SearchMapField {...{ mode, isMasqueraded }} />
                    {isSearchActive && (
                        <div css={campusMapping.searchContainer}>
                            <ResultsMapList selectBuilding={selectBuilding} />
                        </div>
                    )}
                </Fragment>

                {!isSearchActive && (
                    <Fragment>
                        {(mode === campusMappingMode.LIST || mode === campusMappingMode.DETAIL) && (
                            <div
                                css={[
                                    campusMapping.mapContainer,
                                    isMapFullscreen && {
                                        height: isMasqueraded ? "calc(100% - 56px)" : "100%",
                                        "@media (max-width: 1023px)": {
                                            height: isMasqueraded ? "calc(100% - 80px)" : "100%",
                                        },
                                    },
                                    isMasqueraded && campusMapping.mapContainerIsMasqueraded,
                                ]}
                            >
                                <Map
                                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&v=3.exp&libraries=geometry`}
                                    loadingElement={<div css={{ height: `100%` }} />}
                                    containerElement={<div css={campusMapping.map} />}
                                    mapElement={<div css={{ height: `100%` }} />}
                                    showZoomControl={showZoomControl}
                                    zoom={zoom}
                                    center={center}
                                    onMapMounted={this.onMapMounted}
                                >
                                    {locations &&
                                        locations.map((building, index) => (
                                            <MarkerItem
                                                key={`${building.id}${Date.now()}`}
                                                index={index}
                                                currentId={
                                                    this.state.building
                                                        ? this.state.building
                                                        : this.isSearchResult
                                                        ? id
                                                        : null
                                                }
                                                building={building}
                                                showBuildingDetails={showBuildingDetails}
                                            />
                                        ))}
                                </Map>
                                <ButtonFloating
                                    onClick={() => toggleFullscreenMap()}
                                    icon={isMapFullscreen ? "fullscreenExit" : "fullscreen"}
                                    iconColor={colors.black87}
                                    css={[
                                        campusMapping.btnFullscreen,
                                        isMapFullscreen && campusMapping.btnFullscreenActive,
                                    ]}
                                />
                            </div>
                        )}

                        {(mode === campusMappingMode.LIST || mode === campusMappingMode.DETAIL) && (
                            <div css={[campusMapping.contentContainer]}>
                                {mode === campusMappingMode.DETAIL && (
                                    <button
                                        onClick={() => hideBuildingDetails()}
                                        css={campusMapping.btnBack}
                                        type="button"
                                    >
                                        <Icon
                                            name="arrowLeft"
                                            fill={colors.black87}
                                            width={18}
                                            height={18}
                                        />
                                    </button>
                                )}

                                <Container width={592}>
                                    {mode === campusMappingMode.LIST && locations && (
                                        <div css={campusMapping.locationsList}>
                                            <div css={campusMapping.contentHeading}>Buildings</div>
                                            {locations.map((building, index) => (
                                                <BuildingItem
                                                    key={index}
                                                    index={index}
                                                    building={building}
                                                    showBuildingDetails={showBuildingDetails}
                                                />
                                            ))}
                                        </div>
                                    )}

                                    {mode === campusMappingMode.DETAIL && location && (
                                        <div css={campusMapping.buildingInfo}>
                                            <div css={buildingInfo.body} key={location.id}>
                                                <div css={buildingInfo.header}>
                                                    <div css={buildingInfo.headerInner}>
                                                        <div css={buildingInfo.code}>
                                                            <div
                                                                css={[
                                                                    buildingInfo.codeInner,
                                                                    {
                                                                        backgroundColor:
                                                                            markerColors[
                                                                                locationIndex
                                                                            ],
                                                                    },
                                                                ]}
                                                            >
                                                                <span css={buildingInfo.codeText}>
                                                                    {location.code}
                                                                </span>
                                                            </div>
                                                            <div css={buildingInfo.shapeBottomCode}>
                                                                <svg
                                                                    preserveAspectRatio="none"
                                                                    viewBox="0 0 48 8"
                                                                    width="100%"
                                                                    height="8"
                                                                >
                                                                    <path
                                                                        d="M0 0h48L0 8z"
                                                                        fill={
                                                                            markerColors[
                                                                                locationIndex
                                                                            ]
                                                                        }
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div css={buildingInfo.headerInfo}>
                                                            <div css={buildingInfo.name}>
                                                                {location.name}
                                                            </div>
                                                            <div css={buildingInfo.address}>
                                                                {location.address}
                                                            </div>
                                                            <div css={buildingInfo.address}>
                                                                {location.city} {location.postcode}
                                                            </div>
                                                            <div css={buildingInfo.directions}>
                                                                <Button
                                                                    onClick={() => {
                                                                        window.open(
                                                                            `https://www.google.com/maps/dir/Current+Location/${location.latitude},${location.longitude}`,
                                                                            "_blank"
                                                                        );
                                                                        GTM.dispatch({
                                                                            event: "genericClick",
                                                                            targetName:
                                                                                "Get directions",
                                                                        });
                                                                        return true;
                                                                    }}
                                                                    small
                                                                    textIcon={{
                                                                        name: "linkExternal",
                                                                        width: 10,
                                                                        height: 10,
                                                                    }}
                                                                >
                                                                    Get directions
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div css={buildingInfo.imgContainer}>
                                                        <img
                                                            src={location.imageUrl}
                                                            css={buildingInfo.img}
                                                            alt={location.name}
                                                        />
                                                    </div>
                                                </div>
                                                <div css={buildingInfo.text}>
                                                    {location.description &&
                                                        Parser(location.description)}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Container>
                            </div>
                        )}
                    </Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({
    CampusMapping: { locations, location, isSearchActive },
    Auth: { isMasqueraded },
}) => ({
    locations,
    location,
    isSearchActive,
    isMasqueraded,
});

const mapDispatchToProps = {
    getLocations: actionCreators.getLocations.create,
    getLocationDetails: actionCreators.getLocationDetails.create,
    clearLocationDetails: actionCreators.clearLocationDetails.create,
    toggleSearchActive: actionCreators.toggleSearchActive.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
