import { Component, Fragment } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { connect } from "react-redux";
import { BeatLoader } from "react-spinners";
import { Scrollbars } from "react-custom-scrollbars";
import { withRouter, matchPath } from "react-router-dom";
import actionCreators from "../../../store/MainMenu/actionCreators";
import authActionCreators from "../../../store/Auth/actionCreators";
import feedbackActionCreators from "../../../store/Feedback/actionCreators";
import MainMenuButton from "./MainMenuButton";
import MainMenuTabbar from "./MainMenuTabbar";
import { mainMenu, mainMenuButton, mainMenuHeader, mainMenuTabBar } from "./MainMenuStyles";
import Avatar from "../UI/Avatar";
import { colors } from "../../styles/Colors";
import { spacing } from "../../styles/Spacing";
import ModalBox, { ModalBoxText, ModalBoxActions } from "../../components/UI/ModalBox";
import ButtonsGroup from "../../components/UI/ButtonsGroup";
import ButtonText from "../../components/UI/ButtonText";
import ButtonShape from "../UI/ButtonShape";
import GTM from "../../../common/services/GTM";
import LSELogo from "../../images/logo-lse.svg";
import { Link } from "react-router-dom";

const LoadingScreen = () => (
    <div className="loading">
        <BeatLoader color="#ffffff" width="70px" />
    </div>
);

class MainMenu extends Component {
    state = {
        open: false,
        logoutModalOpen: false,
        expandedSections: [],
        parsedLinks: null,
    };

    componentDidMount() {
        const { getSidelinks } = this.props;
        getSidelinks();
    }

    componentDidUpdate(prevProps) {
        const { sidelinks } = this.props;

        if (prevProps.sidelinks.length === 0 && sidelinks.length > 0) {
            const parsed = this.formatSideLinks(sidelinks);
            let expanded = [];
            sidelinks.forEach(item => {
                if (item.isExpanded) expanded.push(item.id);
            });
            if (expanded.length > 0) this.setState({ expandedSections: expanded });
            this.setState({ parsedLinks: parsed });
        }
    }

    handleToggle = () => {
        this.setState(({ open }) => ({ open: !open }));
    };

    hideMenu = () => {
        this.setState(() => ({ open: false }));
    };

    displayFeedbackForm = () => {
        const { displayFeedbackForm } = this.props;
        displayFeedbackForm();
    };

    handleExpandSection = id => {
        const { expandedSections } = this.state;

        if (!expandedSections.find(i => i === id)) {
            this.setState(() => ({
                expandedSections: [...expandedSections, id],
            }));
        } else {
            this.setState(() => ({
                expandedSections: expandedSections.filter(i => i !== id),
            }));
        }
    };

    formatSideLinks = sideLinks => {
        let res = [];

        const getChildren = parentIndex => {
            let res = [];
            const parentLvl = sideLinks[parentIndex].lvl;
            const childLvl = sideLinks[parentIndex + 1].lvl;
            for (let i = parentIndex + 1; i < sideLinks.length; i++) {
                if (sideLinks[i].lvl === parentLvl) break;
                if (i > parentIndex && sideLinks[i].lvl === childLvl)
                    res.push({ ...sideLinks[i], index: i });
            }

            return res.map(item =>
                item.url
                    ? {
                          id: item.id,
                          name: item.name,
                          link: item.url,
                      }
                    : {
                          id: item.id,
                          name: item.name,
                          items: getChildren(item.index).map(j => {
                              return {
                                  id: j.id,
                                  name: j.name,
                                  link: j.url,
                              };
                          }),
                          isExpanded: item.isExpanded,
                      }
            );
        };

        sideLinks.forEach((item, index) => {
            if (item.lvl === 1) {
                res.push({
                    id: item.id,
                    name: item.name,
                    items:
                        sideLinks[index + 1] && sideLinks[index + 1].lvl > item.lvl
                            ? getChildren(index)
                            : [],
                    isExpanded: item.isExpanded,
                });
            }
        });

        return res;
    };

    render() {
        const {
            firstName,
            lastName,
            email,
            photoUrl,
            lseIdNumber,
            isLoading,
            logout,
            history,
            unseenCount,
            studentsList,
            isDefaultCalendar,
            isMasqueraded,
        } = this.props;
        const { expandedSections, parsedLinks } = this.state;
        const { displayFeedbackForm } = this;

        const matchFeedEvent = matchPath(history.location.pathname, {
            path: "/event/:id",
            exact: true,
            strict: false,
        });

        return (
            <Fragment>
                <div
                    css={[
                        mainMenu.body,
                        this.state.open && mainMenu.mobileVisible,
                        isMasqueraded && mainMenu.isMasqueraded,
                    ]}
                >
                    <Scrollbars autoHide css={mainMenu.inner}>
                        <div css={mainMenuHeader.body}>
                            <div css={mainMenuHeader.logo}>
                                <img alt="LSESU logo" src={LSELogo} />
                                <p>Student Hub</p>
                                <div css={mainMenuHeader.logoShape} />
                            </div>
                            <div css={mainMenuHeader.avatar}>
                                {lseIdNumber ? (
                                    <Link to={`/myprogramme/student/${lseIdNumber}`}>
                                        <Avatar
                                            src={photoUrl}
                                            big
                                            initials={firstName.charAt(0) + lastName.charAt(0)}
                                        />
                                    </Link>
                                ) : (
                                    <Avatar
                                        src={photoUrl}
                                        big
                                        initials={firstName.charAt(0) + lastName.charAt(0)}
                                    />
                                )}
                            </div>
                            <div css={mainMenuHeader.right}>
                                <div css={[mainMenuHeader.userName, mainMenuHeader.ellipsis]}>
                                    {firstName} {lastName}
                                </div>
                                <div css={[mainMenuHeader.userEmail, mainMenuHeader.ellipsis]}>
                                    {email}
                                </div>
                                <button
                                    onClick={() => {
                                        history.push("/profile");
                                        this.hideMenu();
                                    }}
                                    css={mainMenuHeader.profileBtn}
                                >
                                    Edit profile
                                </button>
                            </div>
                        </div>
                        <div css={[mainMenu.section, mainMenu.sectionHideOnMobile]}>
                            {/* 
                            <div css={[mainMenu.sectionHeading, mainMenu.sectionHeadingBig]}>
                                Student Hub
                            </div>
                            */}
                            <MainMenuButton
                                role="presentation"
                                icon="feed"
                                size="22"
                                link="/feed"
                                isActive={
                                    history.location.pathname === "/feed" ||
                                    history.location.pathname.includes("/post/") ||
                                    !!matchFeedEvent ||
                                    history.location.pathname.includes("/channel/")
                                }
                            >
                                Home
                            </MainMenuButton>
                            <MainMenuButton
                                role="presentation"
                                icon="calendar"
                                size="23"
                                link={
                                    isDefaultCalendar ? "/calendar/list" : "/calendar/day-schedule"
                                }
                                isActive={history.location.pathname.startsWith("/calendar/")}
                            >
                                Calendar
                            </MainMenuButton>
                            <MainMenuButton
                                role="presentation"
                                icon="clock"
                                size="19"
                                link="/office-hours"
                                isActive={history.location.pathname.startsWith("/office-hours")}
                            >
                                Make a booking
                            </MainMenuButton>
                            <MainMenuButton
                                role="presentation"
                                icon="notification"
                                size="22"
                                link="/notifications"
                                isActive={history.location.pathname.startsWith("/notifications")}
                                badge={unseenCount}
                            >
                                Notifications
                            </MainMenuButton>
                        </div>

                        {studentsList &&
                            studentsList[0] &&
                            studentsList[0].students &&
                            studentsList[0].students.length > 0 && (
                                <div css={mainMenu.section}>
                                    <MainMenuButton
                                        role="presentation"
                                        icon="myprogramme"
                                        size="22"
                                        link="/myprogramme"
                                        isActive={history.location.pathname.startsWith(
                                            "/myprogramme"
                                        )}
                                        style={{ marginTop: 7 }}
                                        onClick={() => this.hideMenu()}
                                    >
                                        My programme
                                    </MainMenuButton>
                                </div>
                            )}
                        <div css={mainMenu.section}>
                            <MainMenuButton
                                icon="marker"
                                size="19"
                                link="/campus-mapping"
                                isActive={history.location.pathname.startsWith("/campus-mapping")}
                                onClick={() => this.hideMenu()}
                            >
                                Campus map
                            </MainMenuButton>
                            <MainMenuButton
                                icon="molecule"
                                size="24"
                                link="/support-map"
                                isActive={history.location.pathname.startsWith("/support-map")}
                                onClick={() => this.hideMenu()}
                            >
                                Find support at LSE
                            </MainMenuButton>
                        </div>

                        <div css={mainMenu.section}>
                            <MainMenuButton
                                role="presentation"
                                icon="channels"
                                size="18"
                                link="/my-channels"
                                isActive={history.location.pathname === "/my-channels"}
                                onClick={() => this.hideMenu()}
                            >
                                My channels
                            </MainMenuButton>
                            <MainMenuButton
                                icon="bookmark"
                                size="18"
                                link="/my-saved-items"
                                isActive={history.location.pathname === "/my-saved-items"}
                                onClick={() => this.hideMenu()}
                            >
                                Saved items
                            </MainMenuButton>
                        </div>

                        <div css={mainMenu.section}>
                            <MainMenuButton
                                icon="help"
                                size="20"
                                link="https://info.lse.ac.uk/current-students/Student-Hub-faqs"
                                target="_blank"
                                isActive={false}
                            >
                                Student Hub help
                            </MainMenuButton>

                            <MainMenuButton
                                icon="envelope"
                                size="20"
                                onClick={() => displayFeedbackForm()}
                                link=""
                                isActive={history.location.pathname.startsWith("/feedback")}
                            >
                                Contact us
                            </MainMenuButton>

                            <MainMenuButton icon="cookie" size="20" onClick={null} link={""}>
                                {/*  eslint-disable-next-line react/no-unknown-property */}
                                <div title="Cookie Settings" className="ot-sdk-show-settings">
                                    Cookie settings
                                </div>
                            </MainMenuButton>
                            <MainMenuButton
                                icon="cookie"
                                size="20"
                                onClick={() => displayFeedbackForm()}
                                link={"https://www.lse.ac.uk/lse-information/cookies"}
                                target="_blank"
                            >
                                Cookie policy
                            </MainMenuButton>
                        </div>

                        {parsedLinks &&
                            parsedLinks.length > 0 &&
                            parsedLinks.map((section, sIndex) => (
                                <div css={mainMenu.section} key={`s${sIndex}`}>
                                    {/* top-level section */}
                                    <button
                                        css={[
                                            mainMenu.sectionHeading,
                                            mainMenu.sectionHeadingIcon,
                                            expandedSections.includes(section.id) &&
                                                mainMenu.sectionHeadingIconActive,
                                        ]}
                                        onClick={() => this.handleExpandSection(section.id)}
                                    >
                                        {section.name}
                                    </button>
                                    {expandedSections.includes(section.id) && (
                                        <div className="subMenu">
                                            {section.items.map((item, index) => {
                                                // sub- (2nd) level normal link
                                                return item.link ? (
                                                    <MainMenuButton
                                                        key={`s${sIndex}i${index}`}
                                                        icon="link"
                                                        size="9"
                                                        link={item.link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        onClick={() => {
                                                            GTM.dispatch({
                                                                event: "genericClick",
                                                                targetName: `${item.name}`,
                                                            });
                                                            return true;
                                                        }}
                                                    >
                                                        {item.name}
                                                    </MainMenuButton>
                                                ) : (
                                                    <div
                                                        key={`s${sIndex}i${index}`}
                                                        css={mainMenu.subSection}
                                                    >
                                                        {item.name && (
                                                            // sub- (2nd) level sub-section
                                                            <button
                                                                css={[
                                                                    mainMenuButton.bodySub,
                                                                    mainMenu.sectionHeadingIcon,
                                                                    expandedSections.includes(
                                                                        item.id
                                                                    ) &&
                                                                        mainMenu.sectionHeadingIconActive,
                                                                ]}
                                                                key={`s${sIndex}i${index}`}
                                                                onClick={() =>
                                                                    this.handleExpandSection(
                                                                        item.id
                                                                    )
                                                                }
                                                            >
                                                                <span>{item.name}</span>
                                                            </button>
                                                        )}
                                                        {expandedSections.includes(item.id) && (
                                                            <div className="subSubMenu">
                                                                {item.items &&
                                                                    item.items.length > 0 &&
                                                                    item.items.map(subIitem => (
                                                                        <MainMenuButton
                                                                            key={subIitem.name}
                                                                            icon="link"
                                                                            size="9"
                                                                            link={
                                                                                subIitem.link
                                                                                    ? subIitem.link
                                                                                    : "#"
                                                                            }
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            onClick={() => {
                                                                                GTM.dispatch({
                                                                                    event:
                                                                                        "genericClick",
                                                                                    targetName: `${subIitem.name}`,
                                                                                });
                                                                                return true;
                                                                            }}
                                                                        >
                                                                            {subIitem.name}
                                                                        </MainMenuButton>
                                                                    ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            ))}

                        <div css={[mainMenu.section, mainMenu.logout]}>
                            <MainMenuButton
                                icon="times"
                                size="15"
                                link=""
                                onClick={() => this.setState({ logoutModalOpen: true })}
                                isLogoutButton
                            >
                                Log out
                            </MainMenuButton>
                        </div>
                    </Scrollbars>
                </div>
                <div css={[mainMenuTabBar.body]}>
                    <div css={mainMenuTabBar.inner}>
                        <MainMenuTabbar
                            icon="feed"
                            size="24"
                            isActive={
                                (!this.state.open && history.location.pathname === "/feed") ||
                                (!this.state.open &&
                                    history.location.pathname.includes("/post/")) ||
                                (!this.state.open && !!matchFeedEvent) ||
                                (!this.state.open &&
                                    history.location.pathname.includes("/channel/"))
                            }
                            onClick={() => {
                                history.push("/feed");
                                this.hideMenu();
                            }}
                            tabbar
                        >
                            News feed
                        </MainMenuTabbar>
                        <MainMenuTabbar
                            icon="calendar"
                            size="24"
                            isActive={
                                !this.state.open && history.location.pathname.includes("/calendar/")
                            }
                            onClick={() => {
                                history.push(
                                    isDefaultCalendar ? "/calendar/list" : "/calendar/day-schedule"
                                );
                                this.hideMenu();
                            }}
                            tabbar
                        >
                            Calendar
                        </MainMenuTabbar>
                        <MainMenuTabbar
                            icon="clock"
                            size="24"
                            onClick={() => {
                                history.push("/office-hours");
                                this.hideMenu();
                            }}
                            isActive={
                                !this.state.open &&
                                history.location.pathname.includes("/office-hours")
                            }
                            tabbar
                        >
                            Bookings
                        </MainMenuTabbar>
                        <MainMenuTabbar
                            icon="menu"
                            size="24"
                            link=""
                            onClick={() => this.handleToggle()}
                            isActive={this.state.open}
                            tabbar
                        >
                            Menu
                        </MainMenuTabbar>
                    </div>
                </div>
                <ModalBox
                    open={this.state.logoutModalOpen}
                    onClose={() => this.setState({ logoutModalOpen: false })}
                    modalWidth={310}
                >
                    <ModalBoxText>
                        <p>Are you sure you want to logout?</p>
                    </ModalBoxText>
                    <ModalBoxActions marginTopValue={spacing.space5}>
                        <ButtonsGroup marginBottomValue={spacing.space1comma5}>
                            <ButtonShape primary fullWidth onClick={logout}>
                                Yes, logout
                            </ButtonShape>
                        </ButtonsGroup>
                        <ButtonsGroup>
                            <ButtonText
                                bold
                                onClick={() => this.setState({ logoutModalOpen: false })}
                                color={colors.black50}
                            >
                                No, cancel
                            </ButtonText>
                        </ButtonsGroup>
                    </ModalBoxActions>
                </ModalBox>
                {isLoading && <LoadingScreen />}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    title: state.MainMenu.title,
    firstName: state.Auth.user.firstName,
    lastName: state.Auth.user.lastName,
    lseIdNumber: state.Auth.user.lseIdNumber,
    email: state.Auth.user.email,
    photoUrl: state.Auth.user.photoUrl,
    isLoading: state.MainMenu.isLoading,
    sidelinks: state.MainMenu.sidelinks,
    unseenCount: state.Notifications.unseenCount,
    studentsList: state.MyProgramme.studentsList,
    isDefaultCalendar: state.calendar.fullList.isDefaultCalendar,
    isMasqueraded: state.Auth.isMasqueraded,
});

const dispatchToProps = {
    logout: authActionCreators.logout.create,
    getSidelinks: actionCreators.getSidelinks.create,
    displayFeedbackForm: feedbackActionCreators.displayFeedbackForm.create,
};

export default withRouter(connect(mapStateToProps, dispatchToProps)(MainMenu));
