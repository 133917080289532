/** @jsx jsx */
import { jsx } from "@emotion/core";
import history from "../../common/services/history";
import styles from "../../common/components/FilterBar/FilterBarStyles";
import { Container } from "../../common/components/UI/Container";
import Icon from "../../common/components/UI/Icon";
import { colors } from "../../common/styles/Colors";
import { useSelector } from "react-redux";

const buttons = [
    {
        link: "list",
        text: "Schedule view",
        icon: "calendarSchedule",
    },
    {
        link: "day-schedule",
        text: "Day view",
        icon: "calendarDay",
    },
];

const CalendarBar = () => {
    const { isMasqueraded } = useSelector(state => state.Auth);
    return (
        <div css={[styles.body, styles.bodyCalendar, isMasqueraded && styles.bodyIsMasqueraded]}>
            <Container width={592} noPadding>
                <div css={styles.bodySpaceBetween}>
                    <div css={styles.bodyLeft}>
                        {buttons.map(button => (
                            <button
                                key={button.link}
                                onClick={() => history.push(`/calendar/${button.link}`)}
                                css={[
                                    styles.btn,
                                    history.location.pathname === `/calendar/${button.link}` &&
                                        styles.btnActive,
                                ]}
                            >
                                <Icon
                                    name={button.icon}
                                    width={20}
                                    height={20}
                                    fill={colors.black87}
                                    css={styles.btnIcon}
                                />
                                {button.text}
                                <span
                                    css={[
                                        styles.btnBorder,
                                        history.location.pathname === `/calendar/${button.link}` &&
                                            styles.btnBorderActive,
                                    ]}
                                >
                                    <svg
                                        preserveAspectRatio="none"
                                        viewBox="0 0 375 4"
                                        width="100%"
                                        height="4"
                                    >
                                        <path
                                            d="M375 0L0 4h375V0z"
                                            fill={
                                                window.innerWidth > 1024
                                                    ? colors.black87
                                                    : colors.white
                                            }
                                        />
                                    </svg>
                                </span>
                            </button>
                        ))}
                    </div>
                    <div css={styles.bodyRight}>
                        <button
                            key={"sync"}
                            onClick={() => history.push(`/calendar/sync`)}
                            css={[
                                styles.btn,
                                history.location.pathname.includes("/calendar/sync") &&
                                    styles.btnActive,
                            ]}
                        >
                            <Icon
                                name={"syncAlt"}
                                width={20}
                                height={20}
                                fill={colors.black87}
                                css={styles.btnIcon}
                            />
                            Sync
                            <span
                                css={[
                                    styles.btnBorder,
                                    history.location.pathname.includes("/calendar/sync") &&
                                        styles.btnBorderActive,
                                ]}
                            >
                                <svg
                                    preserveAspectRatio="none"
                                    viewBox="0 0 375 4"
                                    width="100%"
                                    height="4"
                                >
                                    <path
                                        d="M375 0L0 4h375V0z"
                                        fill={
                                            window.innerWidth > 1024 ? colors.black87 : colors.white
                                        }
                                    />
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default CalendarBar;
