import { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import moment from "moment";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { connect } from "react-redux";
import { submit } from "redux-form";
import history from "./../../../common/services/history";
import { headerMobile, headerTextButton } from "./HeaderStyles";
import HeaderBackButton from "./HeaderBackButton";
import { colors } from "../../styles/Colors";
import Icon from "../UI/Icon";
import ContextMenu from "../ContextMenu";
import PromptDialog from "../PromptDialog";
import { calendarView } from "./helpers";
import { Link } from "react-router-dom";
import actionCreators from "../../../store/Calendar/Event/actionCreators";
import officeActionCreators from "../../../store/Calendar/OfficeHours/actionCreators";
import calendarBoardActionCreators from "../../../store/Calendar/CalendarBoard/actionCreators";
import { formatdateLength } from "../../../app/Calendar/utils";

class HeaderCalendarMobile extends Component {
    state = { promptOpen: false };

    handleModalOpen = () => {
        this.setState({ promptOpen: true });
    };

    handleModalClose = confirm => {
        const { uid, removeAppointment } = this.props;
        this.setState({ promptOpen: false });
        if (confirm) {
            removeAppointment(uid);
        }
    };

    render() {
        const {
            uid,
            calendarType,
            removeEvent,
            submitForm,
            origin,
            item,
            isLoading,
            setRebookApointment,
            removeAppointment,
            mode,
            currentMonth,
            currentDay,
            currentYear,
            toggleCalendarBoard,
            calendarBoardVisible,
            isMasqueraded,
        } = this.props;
        const formName = history.location.pathname.includes("/calendar/event/edit")
            ? "editEvent"
            : "addEvent";

        const calendarEditMyEvents = [
            {
                to: `/calendar/event/edit/?uid=${uid}&calendarType=${calendarType}`,
                text: "Edit event",
                icon: "editAlt",
            },
            {
                to: "",
                text: "Delete event",
                icon: "times",
                onClick: () =>
                    removeEvent({
                        uid,
                        calendarType,
                        origin: origin ? origin : "list",
                    }),
            },
        ];

        const calendarEditAppointments = [
            {
                to: `/calendar/event/edit-notes/?uid=${uid}&calendarType=${calendarType}`,
                text: "Edit event",
                icon: "editAlt",
            },
        ];

        // disable cancelling if appointment already started
        if (calendarType === "appointments" && moment(item.startAt).isAfter()) {
            calendarEditAppointments.push({
                to: "",
                text: "Cancel appointment",
                icon: "times",
                onClick: () => this.handleModalOpen(),
            });

            if (item.teamId) {
                calendarEditAppointments.push({
                    to: "",
                    text: `Rebook with ${item.team}`,
                    icon: "calendarClock",
                    iconSize: 22,
                    onClick: () => {
                        setRebookApointment(item);
                        history.push(`/office-hours/team/${item.teamId}?r=1`);
                    },
                });
                if (item.publicAcademicName) {
                    calendarEditAppointments.push({
                        to: "",
                        text: `Rebook with ${item.publicAcademicName}`,
                        icon: "calendarClock",
                        iconSize: 22,
                        onClick: () => {
                            setRebookApointment(item);
                            history.push(`/office-hours/academic/${item.academicId}?r=1`);
                        },
                    });
                }
            } else {
                calendarEditAppointments.push({
                    to: "",
                    text: `Rebook with ${item.publicAcademicName}`,
                    icon: "calendarClock",
                    iconSize: 22,
                    onClick: () => {
                        setRebookApointment(item);
                        history.push(`/office-hours/academic/${item.academicId}?r=1`);
                    },
                });
            }
        }

        const calendarEditOtherAppointments = [
            {
                to: `/calendar/event/edit-notes/?uid=${uid}&calendarType=${calendarType}`,
                text: "Edit event",
                icon: "editAlt",
            },
        ];

        return (
            <div css={[headerMobile.body, isMasqueraded && headerMobile.bodyIsMasqueraded]}>
                <div css={headerMobile.shape} />
                <div css={headerMobile.inner}>
                    <div css={headerMobile.elementLeft}>
                        {history.location.pathname.includes("/calendar/event/new") ||
                        history.location.pathname.includes("/calendar/event/edit") ||
                        history.location.pathname.includes("/calendar/invitees/add") ? (
                            <HeaderBackButton
                                icon="times"
                                iconColor={colors.white}
                                onClick={history.goBack}
                            />
                        ) : history.location.pathname.includes("/calendar/day-schedule") ||
                          history.location.pathname.includes("/calendar/list") ? (
                            <div />
                        ) : (
                            <HeaderBackButton
                                icon={
                                    history.location.pathname.includes("/calendar/sync-ios") ||
                                    history.location.pathname.includes("/calendar/sync-macos") ||
                                    history.location.pathname.includes("/calendar/sync-android") ||
                                    history.location.pathname.includes(
                                        "/calendar/sync-office-outlook"
                                    )
                                        ? "arrowLeft"
                                        : "times"
                                }
                                iconColor={colors.white}
                                onClick={() =>
                                    origin
                                        ? history.push(`/calendar/${origin.toLowerCase()}`)
                                        : history.goBack()
                                }
                            />
                        )}
                        <div css={headerMobile.elementTitle}>
                            <div css={headerMobile.title}>
                                <Switch>
                                    <Route
                                        exact
                                        path="/calendar/list"
                                        render={() => <Fragment>Calendar</Fragment>}
                                    />
                                    <Route
                                        exact
                                        path="/calendar/day-schedule"
                                        render={() => (
                                            <div css={headerMobile.titleRow}>
                                                <div>
                                                    {moment(
                                                        `${currentYear}-${formatdateLength(
                                                            currentMonth + 1
                                                        )}-${currentDay}`
                                                    ).format("ddd Do MMM")}
                                                </div>
                                                <button
                                                    css={headerMobile.titleBtn}
                                                    onClick={() => toggleCalendarBoard()}
                                                >
                                                    <Icon
                                                        name={
                                                            calendarBoardVisible
                                                                ? "triangleUp"
                                                                : "triangleDown"
                                                        }
                                                        width={12}
                                                        height={12}
                                                        fill={colors.white50}
                                                    />
                                                </button>
                                            </div>
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/calendar/event/new"
                                        render={() => <Fragment>Add an event</Fragment>}
                                    />
                                    <Route
                                        exact
                                        path="/calendar/sync"
                                        render={() => <Fragment>Syncing calendars</Fragment>}
                                    />
                                    <Route
                                        exact
                                        path="/calendar/sync-ios"
                                        render={() => <Fragment>Apple iPhone, iPad</Fragment>}
                                    />
                                    <Route
                                        exact
                                        path="/calendar/sync-macos"
                                        render={() => <Fragment>Apple MacBook, iMac, Mac</Fragment>}
                                    />
                                    <Route
                                        exact
                                        path="/calendar/sync-android"
                                        render={() => (
                                            <Fragment>Android or PC (Google Calendar)</Fragment>
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/calendar/sync-office-outlook"
                                        render={() => (
                                            <Fragment>Office 365 or Outlook Calendar</Fragment>
                                        )}
                                    />
                                </Switch>
                            </div>
                        </div>
                    </div>

                    {history.location.pathname.includes("/calendar/event/new") ||
                    history.location.pathname.includes("/calendar/event/edit") ||
                    history.location.pathname.includes("/calendar/invitees/add") ||
                    (history.location.pathname.includes("/calendar/event/") && uid) ? (
                        history.location.pathname.includes("/calendar/event/") && uid ? (
                            history.location.pathname.includes("/calendar/event/edit-notes/") ? (
                                <div css={headerMobile.elementRight}>
                                    <button
                                        type="submit"
                                        onClick={() => submitForm("editEventNotes")}
                                        css={[
                                            headerTextButton.bodyMobile,
                                            isLoading && headerTextButton.bodyMobileIsLoading,
                                        ]}
                                        disabled={isLoading}
                                    >
                                        SAVE
                                    </button>
                                </div>
                            ) : calendarType === "my-events" ? (
                                <div css={headerMobile.elementRight}>
                                    <ContextMenu itemsList={calendarEditMyEvents}>
                                        <Icon
                                            name="editAlt"
                                            width={20}
                                            height={20}
                                            fill={colors.white}
                                        />
                                    </ContextMenu>
                                </div>
                            ) : calendarType === "appointments" ? (
                                mode === "drop-in" ? (
                                    <div css={headerMobile.elementRight}>
                                        <button
                                            css={headerTextButton.bodyMobile}
                                            type="button"
                                            onClick={() => removeAppointment(uid)}
                                        >
                                            <Icon
                                                name="trash"
                                                width={20}
                                                height={20}
                                                fill={colors.white}
                                            />
                                        </button>
                                    </div>
                                ) : (
                                    <div css={headerMobile.elementRight}>
                                        <ContextMenu itemsList={calendarEditAppointments}>
                                            <Icon
                                                name="editAlt"
                                                width={20}
                                                height={20}
                                                fill={colors.white}
                                            />
                                        </ContextMenu>
                                    </div>
                                )
                            ) : calendarType !== "timetables" && calendarType !== "lse-events" ? (
                                <div css={headerMobile.elementRight}>
                                    <ContextMenu itemsList={calendarEditOtherAppointments}>
                                        <Icon
                                            name="editAlt"
                                            width={20}
                                            height={20}
                                            fill={colors.white}
                                        />
                                    </ContextMenu>
                                </div>
                            ) : (
                                <div></div> /* no option for timetables */
                            )
                        ) : history.location.pathname.includes("calendar/invitees/add") ? (
                            <div css={headerMobile.elementRight}>
                                <button
                                    type="submit"
                                    onClick={() =>
                                        history.push(
                                            item.uid
                                                ? {
                                                      pathname: `/calendar/event/edit/`,
                                                      search: `?uid=${item.uid}&calendarType=${item.calendarType}`,
                                                      state: {
                                                          loadInvitees: true,
                                                      },
                                                  }
                                                : {
                                                      pathname: `/calendar/event/new`,
                                                      search: "?",
                                                      state: {
                                                          loadInvitees: true,
                                                      },
                                                  }
                                        )
                                    }
                                    css={[
                                        headerTextButton.bodyMobile,
                                        isLoading && headerTextButton.bodyMobileIsLoading,
                                    ]}
                                    disabled={isLoading}
                                >
                                    SAVE
                                </button>
                            </div>
                        ) : (
                            <div css={headerMobile.elementRight}>
                                <button
                                    type="submit"
                                    onClick={() => submitForm(formName)}
                                    css={[
                                        headerTextButton.bodyMobile,
                                        isLoading && headerTextButton.bodyMobileIsLoading,
                                    ]}
                                    disabled={isLoading}
                                >
                                    SAVE
                                </button>
                            </div>
                        )
                    ) : null}
                    <Switch>
                        <Route
                            exact
                            path="/calendar/list"
                            component={() => (
                                <div css={headerMobile.elementRight}>
                                    <ContextMenu itemsList={calendarView}>
                                        <Icon
                                            name="calendarSchedule"
                                            width={22}
                                            height={22}
                                            fill={colors.white}
                                        />
                                    </ContextMenu>

                                    <div css={headerMobile.syncIcon}>
                                        <Link to={"/calendar/sync"}>
                                            <Icon
                                                name="syncAlt"
                                                width={20}
                                                height={20}
                                                fill={colors.white}
                                            />
                                        </Link>
                                    </div>
                                </div>
                            )}
                        />
                        <Route
                            exact
                            path="/calendar/day-schedule"
                            component={() => (
                                <div css={headerMobile.elementRight}>
                                    <ContextMenu itemsList={calendarView}>
                                        <Icon
                                            name="calendarDay"
                                            width={22}
                                            height={22}
                                            fill={colors.white}
                                        />
                                    </ContextMenu>
                                    <div css={headerMobile.syncIcon}>
                                        <Link to={"/calendar/sync"}>
                                            <Icon
                                                name="syncAlt"
                                                width={20}
                                                height={20}
                                                fill={colors.white}
                                            />
                                        </Link>
                                    </div>
                                </div>
                            )}
                        />
                    </Switch>
                </div>
                {calendarType === "appointments" && (
                    <PromptDialog open={this.state.promptOpen} onClose={this.handleModalClose}>
                        Do you want to cancel this booking?
                    </PromptDialog>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({
    calendar: {
        event: { origin, item },
        board: { currentMonth, currentDay, currentYear, calendarBoardVisible },
    },
    MainView: { isLoading },
    Auth: { isMasqueraded },
}) => ({
    origin,
    item,
    isLoading,
    currentMonth,
    currentDay,
    currentYear,
    calendarBoardVisible,
    isMasqueraded,
});

const mapDispatchToProps = {
    setRebookApointment: officeActionCreators.setRebookApointment.create,
    removeEvent: actionCreators.removeEvent.create,
    removeAppointment: officeActionCreators.removeAppointment.create,
    submitForm: submit,
    toggleCalendarBoard: calendarBoardActionCreators.toggleCalendarBoard.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCalendarMobile);
