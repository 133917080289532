import { colors } from "../../styles/Colors";
import { spacing } from "../../styles/Spacing";
import { fontWeight, fontSize } from "../../styles/Typography";
import bgHeaderMobile from "../../images/header-mobile.svg";
import bgHeaderShapeMobile from "../../images/header-shape-mobile.svg";
import loader from "../../images/loader.svg";
import loaderWhite from "../../images/loader-white.svg";

export const header = {
    body: {
        display: "flex",
        // justifyContent: "center",
        width: "100%",
        height: 56,
        backgroundColor: colors.white,
        boxShadow: "0 2px 2px -2px rgba(0, 0, 0, 0.12)",
        paddingLeft: 300,
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 100,
        "@media (max-width: 1023px)": {
            display: "none",
        },
    },
    bodyCalendar: {
        display: "flex",
        // justifyContent: "center",
        width: "100%",
        height: 56,
        backgroundColor: colors.white,
        boxShadow: "0 2px 2px -2px rgba(0, 0, 0, 0.12)",
        paddingLeft: 300,
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 100,
    },
    bodyCalendarBoard: {
        height: 410,
        flexDirection: "column",
        "@media (max-width: 1023px)": {
            display: "flex",
        },
    },
    bodyCalendarTitle: {
        height: 122,
        flexDirection: "column",
    },
    inner: {
        display: "flex",
        width: "100%",
        maxWidth: 560,
        justifyContent: "space-between",
        alignItems: "stretch",
    },
    elementLeft: {
        width: 56,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: spacing.space1,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space1,
        paddingLeft: spacing.space2,
    },
    actionLeft: {
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        padding: 0,
        border: "0 none",
        outline: "0 none",
        backgroundColor: "transparent",
        width: 24,
        height: 24,
    },
    actionLeftIcon: {
        color: colors.accentA,
    },
    elementCenter: {
        display: "flex",
        alignItems: "center",
        flex: 1,
    },
    title: {
        color: colors.white,
        fontSize: 17,
        fontWeight: fontWeight.medium,
        textAlign: "center",
        width: "100%",
    },
    titleBig: {
        backgroundColor: colors.white,
        color: colors.black87,
        fontSize: fontSize.textBig,
        fontWeight: fontWeight.medium,
        textAlign: "center",
        width: "100%",
        paddingTop: spacing.space3,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space3,
        boxShadow: "0 2px 2px -2px rgba(0, 0, 0, 0.12)",
        paddingLeft: 300,
        position: "fixed",
        top: 50,
        right: 0,
        left: 0,
        zIndex: 105,
        "@media (max-width: 1023px)": {
            display: "none",
        },
    },
    elementRight: {
        width: 56,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: spacing.space1,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space1,
        paddingLeft: spacing.space2,
    },
    actionRight: {
        color: colors.accentA,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        padding: 0,
        border: "0 none",
        outline: "0 none",
        backgroundColor: "transparent",
        width: 24,
        height: 24,
    },
};

export const headerLogo = {
    body: {
        display: "flex",
        alignItems: "flex-end",
        textDecoration: "none",
    },
    img: {
        fontSize: 72,
    },
    label: {
        color: colors.greyDark,
        fontSize: 32,
        lineHeight: 1,
        fontWeight: fontWeight.bold,
        marginBottom: spacing.space1,
        marginLeft: spacing.space3,
    },
};

export const headerSmall = {
    body: {
        display: "flex",
        width: "100%",
        height: 52,
        borderBottomWidth: 1,
        borderBottomColor: colors.black10,
        borderBottomStyle: "solid",
        backgroundColor: colors.white,
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 100,
        // transform: "translateY(-100%)",
    },
    inner: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "stretch",
    },
    elementLeft: {
        width: 56,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "stretch",
        paddingTop: spacing.space2,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
    },
    actionLeft: {
        color: colors.accentA,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        padding: 0,
        border: "0 none",
        outline: "0 none",
        backgroundColor: "transparent",
        width: 24,
        height: 24,
    },
    actionLeftIcon: {
        color: colors.accentA,
    },
    elementCenter: {
        display: "flex",
        flex: 1,
    },
    elementRight: {
        width: 56,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "stretch",
        paddingTop: spacing.space2,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: spacing.space2,
    },
    actionRight: {
        color: colors.accentA,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        padding: 0,
        border: "0 none",
        outline: "0 none",
        backgroundColor: "transparent",
        width: 24,
        height: 24,
    },
};

export const headerMobile = {
    body: {
        display: "none",
        width: "100%",
        height: 56,
        backgroundColor: "#31A590",
        backgroundImage: `url(${bgHeaderMobile})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "0 100%",
        backgroundSize: "cover",
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 110,
        "@media (max-width: 1023px)": {
            display: "flex",
        },
    },
    bodyIsMasqueraded: {
        top: 80,
    },
    bodyTransparent: {
        backgroundColor: "transaprent",
        backgroundImage: "none",
    },
    shape: {
        width: "100%",
        // paddingBottom: "1.66667%",
        height: 10,
        backgroundColor: colors.transparent,
        backgroundImage: `url(${bgHeaderShapeMobile})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "0 0",
        // backgroundSize: "100% 10px",
        position: "fixed",
        top: 56,
        right: 0,
        left: 0,
        zIndex: 115,
    },
    inner: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "stretch",
    },
    elementLeft: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        minWidth: 0,
    },
    elementTitle: {
        paddingRight: spacing.space2,
        paddingLeft: spacing.space2,
        display: "flex",
        flex: 1,
        flexDirection: "column",
        minWidth: 0,
    },
    actionLeft: {
        color: colors.accentA,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        paddingTop: 0,
        paddingRight: spacing.space2,
        paddingBottom: 0,
        paddingLeft: spacing.space2,
        border: "0 none",
        outline: "0 none",
        backgroundColor: "transparent",
        // width: 24,
        height: 24,
        "@media (max-width: 370px)": {
            paddingRight: 0,
        },
    },
    actionLeftIcon: {
        color: colors.accentA,
    },
    // elementCenter: {
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     flex: 1,
    //     width: "calc(100% - 112px)",
    // },
    title: {
        color: colors.white,
        fontSize: 24,
        fontWeight: fontWeight.black,
        // width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        "@media (max-width: 375px)": {
            fontSize: 20,
        },
    },
    titleDark: {
        color: colors.black87,
    },
    titleRow: {
        display: "flex",
        alignItems: "center",
        gap: 6,
    },
    titleBtn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 24,
        height: 24,
        border: 0,
        backgroundColor: "transparent",
        padding: 0,
        margin: 0,
    },
    elementRight: {
        width: 56,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "stretch",
        paddingTop: spacing.space2,
        paddingRight: spacing.space2,
        paddingBottom: spacing.space2,
        paddingLeft: 0,
    },
    actionRight: {
        color: colors.accentA,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        padding: 0,
        border: "0 none",
        outline: "0 none",
        backgroundColor: "transparent",
        width: 24,
        height: 24,
    },
    syncIcon: {
        paddingLeft: spacing.space2,
    },
};

export const headerBackButton = {
    body: {
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        paddingTop: 0,
        paddingRight: spacing.space2,
        paddingBottom: 0,
        paddingLeft: spacing.space2,
        border: "0 none",
        outline: "0 none",
        backgroundColor: "transparent",
        // width: 24,
        height: 24,
        "@media (max-width: 370px)": {
            paddingRight: 0,
        },
    },
};

export const headerTextButton = {
    body: {
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        border: "0 none",
        outline: "0 none",
        backgroundColor: "transparent",
        height: 24,
        color: colors.black87,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        letterSpacing: 0.4,
        lineHeight: 1.28,
    },
    bodyIsLoading: {
        backgroundImage: `url(${loader})`,
        backgroundColor: "transparent",
        backgroundPosition: "0 50%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "30px",
        paddingLeft: 30,
        color: colors.black40,
        cursor: "default",
    },
    bodyMobile: {
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        border: "0 none",
        outline: "0 none",
        backgroundColor: "transparent",
        height: 24,
        color: colors.white,
        fontSize: fontSize.textSmall,
        fontWeight: fontWeight.normal,
        letterSpacing: 0.4,
        lineHeight: 1.28,
    },
    bodyMobileIsLoading: {
        backgroundImage: `url(${loaderWhite})`,
        backgroundColor: "transparent",
        backgroundPosition: "0 50%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "30px",
        paddingLeft: 30,
        color: colors.white60,
        cursor: "default",
    },
};
