import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import history from "./../../../common/services/history";
import actionCreators from "../../../store/Search/actionCreators";
import { Container } from "../../../common/components/UI/Container";
import styles from "./FilterBarStyles";
import Icon from "../UI/Icon";
import { colors } from "../../styles/Colors";
import MediaQuery from "react-responsive";

class FilterBar extends Component {
    constructor(props) {
        super(props);
        const { filters } = props;
        this.state = {
            activeFilter: filters ? filters[0].value : null,
        };
    }

    componentDidUpdate(prevProps) {
        const { filters } = this.props;
        const { activeFilter } = this.state;

        // validate
        if (
            filters &&
            filters.length > 0 &&
            history.location.pathname === "/feed" &&
            filters !== prevProps.filters &&
            !filters.find(filter => filter.value === activeFilter)
        ) {
            this.handleClick(filters[0].value);
        }
    }

    componentWillUnmount() {
        const { hideSearchBar } = this.props;
        hideSearchBar();
    }

    handleClick = value => {
        this.setState({ activeFilter: value });
        const { filters } = this.props;
        const filterIndex = filters.findIndex(filter => filter.value === value);
        if (filters[filterIndex].handler) {
            filters[filterIndex].handler(value);
        }
    };

    showSearchBar = () => {
        const { toggleActiveForm, enableResults } = this.props;
        toggleActiveForm(true);
        enableResults();
    };

    render() {
        const { activeFilter } = this.state;
        const { filters, relative, show, noPadding, isMasqueraded } = this.props;
        const { showSearchBar } = this;

        return (
            filters &&
            filters.length > 0 && (
                <div
                    css={[
                        styles.body,
                        show && styles.bodyIsSearch,
                        relative && styles.bodyRelative,
                        isMasqueraded && styles.bodyIsMasqueraded,
                        show && isMasqueraded && styles.bodyIsMasqueradedIsSearch,
                        relative && isMasqueraded && styles.bodyRelativeIsMasqueraded,
                    ]}
                >
                    <Container width={592} noPadding>
                        <div css={[styles.bodySpaceBetween, noPadding && styles.bodyNoPadding]}>
                            <div css={styles.bodyLeft}>
                                {filters.map(({ nameDesktop, nameMobile, value }) => (
                                    <button
                                        onClick={() => {
                                            this.handleClick(value);
                                        }}
                                        key={nameDesktop}
                                        css={[
                                            styles.btn,
                                            relative && styles.btnRelative,
                                            activeFilter === value ? styles.btnActive : {},
                                        ]}
                                    >
                                        <MediaQuery maxWidth={767}>{nameMobile}</MediaQuery>
                                        <MediaQuery minWidth={768}>{nameDesktop}</MediaQuery>

                                        <span
                                            css={[
                                                styles.btnBorder,
                                                activeFilter === value
                                                    ? styles.btnBorderActive
                                                    : {},
                                            ]}
                                        >
                                            <svg
                                                preserveAspectRatio="none"
                                                viewBox="0 0 375 4"
                                                width="100%"
                                                height="4"
                                            >
                                                <path
                                                    d="M375 0L0 4h375V0z"
                                                    fill={
                                                        window.innerWidth > 1024
                                                            ? colors.black87
                                                            : colors.white
                                                    }
                                                />
                                            </svg>
                                        </span>
                                    </button>
                                ))}
                            </div>
                            {history.location.pathname === "/feed" && !show && (
                                <div css={styles.bodyRight} onClick={() => showSearchBar()}>
                                    <button type="button" css={styles.btnSearch}>
                                        <Icon
                                            name="search"
                                            width={18}
                                            height={18}
                                            fill={colors.black87}
                                        />
                                    </button>
                                </div>
                            )}
                        </div>
                    </Container>
                </div>
            )
        );
    }
}

const mapStateToProps = ({ Search: { show }, Auth: { isMasqueraded } }) => ({
    show,
    isMasqueraded,
});

const mapDispatchToProps = {
    toggleActiveForm: actionCreators.toggleSearchBar.create,
    enableResults: actionCreators.enableResults.create,
    hideSearchBar: actionCreators.hideSearchBar.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);
