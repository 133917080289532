import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import actionCreator from "../../../store/Auth/actionCreators";

const InteractiveLoginPage = ({
    match: {
        params: { hash },
    },
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (hash) dispatch(actionCreator.interactiveLogin.create({ token: hash }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div />;
};

export default InteractiveLoginPage;
