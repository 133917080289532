import React, { useEffect, useState } from "react";
import { Route } from "react-router";
import { Redirect } from "react-router-dom";
import { store } from "../../store/";
export const publicRoutes = [
    "/error",
    "/maintenance",
    "/forgot-password",
    "/password-confirmation",
    "/register",
    "/login",
    "/interactive-login",
    "/welcome",
];

const PrivateRoute = ({ component: Component, location, ...rest }) => {
    const [redirectPath, setRedirectPath] = useState("");

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        const { pathname, search } = store.getState().router.location;
        const URLSearchParams = require("url-search-params");
        const params = new URLSearchParams(search);
        localStorage.setItem("redirect", pathname);
        localStorage.setItem(
            "redirectAction",
            params && params.has("action") ? params.get("action") : ""
        );
        localStorage.setItem("uid", params.has("uid") ? params.get("uid") : "");
        setRedirectPath(
            pathname !== "/" ? (!!user && !!user.email ? pathname : `/login`) : "/welcome"
        );
    }, []);

    return (
        <Route
            {...rest}
            render={props => {
                const user = JSON.parse(localStorage.getItem("user"));
                return !!user && !!user.email ? (
                    <Component {...props} />
                ) : (
                    !publicRoutes.includes(props.location.pathname) &&
                        !props.location.pathname.startsWith("/interactive") &&
                        !!redirectPath && <Redirect to={redirectPath} />
                );
            }}
        />
    );
    // }
};

export default PrivateRoute;
