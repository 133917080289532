/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useSelector, useDispatch } from "react-redux";
import actionCreators from "../../store/Auth/actionCreators";
import { colors } from "../styles/Colors";
import Button from "./UI/Button";
import { fontSize } from "../styles/Typography";
import { useMediaQuery } from "react-responsive";

const Masqueraded = () => {
    const { isMasqueraded, user: storeUser } = useSelector(state => state.Auth);
    const { studentsList } = useSelector(state => state.MyProgramme);
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

    return isMasqueraded ? (
        <div css={styles.root}>
            <div css={styles.body}>
                <div css={styles.title}>
                    You&apos;re currently masquerading as{" "}
                    <strong>
                        {storeUser.firstName} {storeUser.lastName}
                    </strong>
                </div>
                {studentsList && studentsList[0] && (
                    <div css={styles.subtitle}>
                        {studentsList[0].programmeName} | {studentsList[0].departmentName}
                    </div>
                )}
            </div>
            <div>
                <Button
                    small={!isMobile}
                    smaller={isMobile}
                    onClick={() => {
                        dispatch(actionCreators.logout.create());
                        window.close();
                    }}
                    style={{ backgroundColor: colors.white }}
                >
                    Stop masquerading
                </Button>
            </div>
        </div>
    ) : (
        <div />
    );
};

const styles = {
    root: {
        color: colors.white,
        backgroundColor: colors.red,
        height: 56,
        display: "flex",
        gap: 48,
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 1000,
        "@media (max-width: 1023px)": {
            height: 80,
            padding: "0 16px",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "stretch",
            gap: 8,
        },
    },
    body: {
        display: "flex",
        flexDirection: "column",
        gap: 2,
    },
    title: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "@media (max-width: 1023px)": {
            fontSize: fontSize.textSmall,
        },
    },
    subtitle: {
        color: colors.white70,
        fontSize: fontSize.textSmaller,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
};

export default Masqueraded;
